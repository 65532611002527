export const LogoUpHorizontal = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 820"
    preserveAspectRatio="xMidYMid meet"
    className="logo-upps"
  >
    <g
      transform="translate(0,820) scale(0.1,-0.1)"
      fill="currentColor"
      stroke="none"
    >
      <g className="svg-group-1">
        <path
          d="M6129 8064 c-414 -467 -516 -920 -328 -1462 50 -144 173 -381 282
-545 161 -242 299 -412 569 -703 262 -283 393 -463 503 -689 200 -410 208
-781 28 -1235 -124 -315 -279 -527 -449 -616 -243 -127 -520 -213 -788 -245
-128 -15 -520 -6 -686 15 -69 9 -260 39 -425 66 -165 27 -315 51 -334 52 -32
3 -30 5 71 70 335 213 902 468 1183 532 96 22 98 23 60 30 -75 15 -257 8 -365
-13 -200 -39 -467 -132 -800 -279 -232 -103 -732 -353 -737 -369 -3 -7 23 -34
57 -60 233 -184 564 -289 1013 -324 196 -15 594 -6 737 16 420 66 809 256
1115 545 44 42 121 109 170 150 243 202 431 440 532 675 137 320 136 692 -4
1049 -52 133 -125 271 -213 404 -106 159 -181 250 -366 443 -378 396 -600 695
-773 1039 -82 166 -159 378 -186 520 -23 115 -31 356 -16 460 27 184 104 377
201 506 28 36 49 68 47 70 -2 3 -46 -43 -98 -102z"
        />
        <path
          d="M6722 7249 c-114 -15 -217 -94 -270 -207 -23 -48 -27 -71 -27 -142 0
-76 4 -93 33 -152 63 -129 174 -198 317 -198 143 0 254 69 317 198 29 59 33
76 33 152 0 71 -4 94 -27 142 -69 149 -212 227 -376 207z"
        />
        <path
          d="M8330 6798 c-206 -172 -427 -364 -490 -427 -129 -130 -254 -290 -327
-419 -51 -91 -136 -272 -130 -278 2 -2 41 40 87 92 239 273 610 573 970 786
108 64 115 66 122 47 5 -11 13 -85 19 -164 17 -221 -6 -518 -62 -790 -59 -287
-173 -612 -302 -860 -24 -47 -41 -85 -38 -85 19 0 126 83 191 149 221 223 414
622 499 1034 43 204 55 342 48 536 -7 182 -21 267 -67 411 -31 95 -116 271
-135 277 -5 1 -179 -138 -385 -309z"
        />
        <path
          d="M8695 4720 c-112 -12 -267 -42 -365 -73 l-75 -23 130 -8 c395 -22
793 -97 1139 -216 190 -66 206 -72 206 -87 0 -24 -122 -172 -215 -262 -314
-300 -737 -517 -1217 -625 l-103 -23 40 -12 c227 -63 701 36 1110 234 422 204
718 488 845 809 17 43 29 80 28 81 -8 8 -861 166 -1020 190 -135 19 -384 27
-503 15z"
        />
        <path
          d="M5645 2151 c-207 -53 -255 -318 -79 -439 141 -96 347 -2 370 170 8
59 -16 142 -55 189 -51 62 -162 99 -236 80z"
        />
      </g>
      <g className="svg-group-2">
        <path
          d="M7290 8164 c0 -6 7 -17 15 -24 30 -25 119 -176 157 -267 58 -138 78
-242 85 -423 4 -129 2 -175 -15 -275 -66 -382 -268 -781 -610 -1205 -47 -58
-89 -110 -93 -116 -6 -7 34 -58 113 -143 67 -72 125 -131 129 -131 11 0 195
230 288 360 222 310 372 627 428 906 24 124 24 378 0 492 -51 234 -165 455
-338 657 -114 134 -159 181 -159 169z"
        />
        <path
          d="M4713 7038 c-176 -315 -210 -759 -92 -1236 92 -375 281 -750 477
-951 68 -69 173 -151 194 -151 5 0 -19 55 -53 121 -156 311 -265 658 -320
1019 -34 220 -33 770 1 770 15 0 197 -108 310 -183 298 -199 590 -453 808
-701 29 -33 52 -57 52 -54 0 14 -65 160 -100 225 -93 173 -239 363 -391 508
-81 78 -828 705 -839 705 -3 0 -24 -33 -47 -72z"
        />
        <path
          d="M6379 5364 c-206 -253 -377 -597 -433 -875 -50 -246 -37 -502 35
-723 85 -255 258 -514 481 -719 134 -123 262 -207 202 -133 -198 246 -301 423
-374 645 -55 167 -70 247 -77 416 -14 378 106 700 409 1093 l80 103 -53 57
c-123 132 -199 212 -203 212 -3 0 -33 -34 -67 -76z"
        />
        <path
          d="M4465 4724 c-115 -7 -246 -24 -437 -59 -309 -57 -783 -147 -787 -151
-7 -6 34 -113 72 -189 221 -428 804 -797 1467 -927 137 -27 393 -32 445 -9 30
13 29 14 -53 32 -467 103 -880 308 -1195 592 -83 74 -216 227 -242 277 -15 30
-15 30 12 41 274 108 579 190 883 238 113 18 177 25 475 49 l90 7 -115 32
c-179 50 -428 77 -615 67z"
        />
        <path
          d="M7695 3330 l-30 -7 40 -7 c207 -40 502 -154 860 -333 161 -81 474
-261 475 -273 0 -3 -64 -15 -143 -27 -78 -13 -239 -39 -357 -58 -317 -52 -476
-67 -725 -67 -314 -1 -546 36 -787 126 -137 51 -177 61 -189 49 -12 -12 53
-63 177 -141 236 -147 489 -241 779 -289 92 -15 168 -18 460 -18 312 1 366 3
495 23 365 57 621 156 813 315 26 21 47 43 47 47 0 12 -557 290 -735 367 -507
222 -790 304 -1031 302 -66 -1 -132 -5 -149 -9z"
        />
      </g>
      <path
        d="M1085 4779 c-396 -52 -759 -278 -889 -552 -97 -205 -68 -395 72 -466
35 -18 179 -47 188 -39 1 2 -6 28 -16 58 -13 38 -19 83 -20 147 -1 236 89 435
253 559 65 49 185 110 238 121 27 5 31 4 25 -13 -3 -10 -60 -237 -126 -504
-178 -715 -218 -935 -207 -1130 11 -191 74 -295 222 -366 103 -49 225 -68 400
-61 230 10 368 54 502 162 181 146 300 392 411 845 42 169 262 1201 262 1226
0 12 -37 14 -208 14 l-208 0 -108 -502 c-239 -1109 -318 -1339 -492 -1424 -51
-25 -70 -28 -149 -28 -96 -1 -130 10 -172 56 -38 40 -53 102 -53 213 0 185 44
382 283 1258 76 277 110 420 103 424 -17 11 -235 12 -311 2z"
      />
      <path
        d="M3107 4040 c-59 -15 -129 -54 -184 -104 l-50 -46 13 63 c7 34 13 68
14 75 0 9 -49 12 -213 12 l-213 0 -153 -722 c-84 -398 -155 -733 -158 -745
l-5 -23 216 0 c119 0 216 2 216 4 0 14 202 957 211 986 62 195 224 302 301
199 45 -60 36 -163 -45 -514 -47 -202 -51 -230 -51 -350 -1 -123 1 -133 27
-187 54 -111 155 -165 287 -156 152 11 265 108 298 256 l9 38 -78 -1 c-72 0
-81 2 -105 26 -51 51 -46 115 36 459 79 330 88 457 40 568 -25 58 -90 123
-147 148 -45 19 -209 28 -266 14z"
      />
      <path
        d="M935 2249 c-360 -49 -713 -269 -849 -529 -107 -204 -81 -422 58 -490
46 -22 181 -49 192 -38 3 4 -3 21 -13 40 -15 28 -18 57 -18 178 0 124 3 156
23 220 85 271 271 427 569 476 255 42 417 -14 493 -169 54 -110 63 -227 31
-382 -46 -224 -174 -423 -334 -519 -51 -30 -153 -63 -164 -53 -2 2 44 226 102
497 57 272 104 494 102 495 -4 4 -432 -57 -436 -61 -3 -3 -96 -429 -206 -947
l-202 -942 210 -3 c116 -1 214 1 217 5 4 5 46 195 95 424 l88 416 86 6 c310
22 576 196 707 462 103 210 111 419 22 596 -75 151 -227 262 -416 303 -92 21
-265 28 -357 15z"
      />
      <path
        d="M6517 2080 c-109 -16 -201 -31 -204 -33 -2 -3 -78 -351 -168 -774
-179 -841 -178 -838 -266 -917 -76 -69 -193 -87 -242 -37 -41 41 -35 91 86
659 l113 532 -216 0 -216 0 -98 -467 c-54 -258 -102 -475 -107 -484 -6 -13
-143 -112 -194 -142 -7 -4 -10 24 -7 86 2 51 -6 209 -17 352 -11 143 -26 370
-32 505 -7 135 -13 246 -14 248 -2 2 -353 -44 -412 -54 -31 -6 -33 -10 -69
-118 -61 -187 -162 -433 -246 -602 -69 -140 -86 -165 -126 -195 -28 -21 -58
-57 -78 -93 -24 -44 -46 -67 -90 -97 l-59 -39 -2 113 c-2 61 -12 245 -23 407
-11 162 -25 380 -31 485 -5 104 -11 191 -13 192 -1 2 -100 -11 -219 -29 l-216
-32 -70 -105 c-131 -202 -251 -320 -361 -356 -28 -9 -53 -15 -56 -12 -2 2 -9
42 -14 87 -28 243 -138 365 -342 377 -337 21 -614 -202 -757 -610 -64 -184
-95 -425 -72 -562 40 -239 231 -372 505 -352 348 25 607 356 661 842 l6 61 62
22 c124 44 294 182 395 320 l47 64 7 -99 c4 -54 18 -208 30 -343 13 -135 23
-301 23 -370 0 -113 -2 -130 -25 -174 -30 -60 -63 -86 -118 -93 -58 -8 -107 4
-142 34 -39 32 -56 66 -65 127 l-8 49 24 -16 c52 -37 139 -10 179 55 41 67 18
145 -50 172 -146 58 -284 -56 -298 -246 -9 -131 31 -253 107 -321 53 -48 89
-66 169 -83 200 -42 410 12 532 135 30 31 92 82 137 114 45 32 85 62 88 67 10
17 25 11 25 -10 0 -42 43 -131 86 -179 91 -101 244 -149 421 -131 139 14 261
60 343 129 25 20 79 59 120 85 41 27 111 77 156 112 l81 63 6 -77 c14 -200
115 -310 296 -320 159 -9 305 52 432 179 l75 77 17 -48 c20 -56 87 -129 146
-159 153 -79 403 -63 593 36 267 139 458 527 458 929 0 349 -124 516 -371 502
-64 -4 -92 -11 -149 -40 -38 -19 -70 -33 -70 -32 0 4 128 614 135 642 7 27 24
27 -228 -8z m-3949 -753 c13 -13 27 -46 34 -79 12 -56 12 -57 -19 -85 -39 -37
-56 -91 -51 -161 4 -47 10 -60 37 -84 l33 -29 -11 -67 c-43 -252 -155 -474
-267 -527 -50 -24 -126 -16 -159 15 -39 37 -48 89 -42 245 6 153 21 235 73
397 51 159 106 262 179 335 34 33 72 63 86 66 40 8 83 -2 107 -26z m4248 -48
c63 -59 90 -168 80 -322 -19 -295 -145 -587 -281 -651 -61 -29 -147 -15 -189
33 -39 43 -33 95 53 502 45 211 86 394 91 407 25 59 194 80 246 31z m-2279
-394 c10 -121 18 -276 18 -345 0 -119 -1 -127 -29 -177 -49 -88 -140 -132
-254 -120 -88 9 -136 57 -148 152 l-7 47 27 -17 c41 -27 103 -15 143 29 54 59
56 137 4 185 l-28 25 86 176 c47 97 102 219 121 272 l35 97 7 -52 c4 -29 15
-151 25 -272z"
      />
      <path
        d="M7851 2079 c-112 -15 -205 -29 -206 -31 -2 -2 -79 -354 -171 -783
-162 -758 -167 -783 -168 -900 0 -101 3 -129 22 -175 47 -120 135 -180 271
-188 173 -9 350 72 466 215 l43 52 15 -37 c56 -137 185 -216 372 -229 276 -18
465 107 539 356 24 82 21 86 -31 46 -27 -20 -82 -53 -123 -73 -62 -30 -89 -37
-155 -40 -72 -3 -84 -1 -115 20 -19 13 -46 44 -60 68 -21 39 -24 58 -25 144
l0 99 79 14 c363 62 623 339 603 644 -11 168 -101 246 -296 256 -124 6 -219
-13 -331 -68 -156 -77 -304 -250 -390 -454 -61 -149 -110 -366 -110 -493 0
-76 -18 -111 -86 -170 -73 -65 -189 -81 -237 -34 -43 43 -39 70 147 941 97
453 176 830 176 837 0 17 19 18 -229 -17z m1104 -719 c12 -14 20 -41 23 -79
12 -159 -87 -351 -228 -441 -47 -30 -153 -70 -187 -70 -19 0 -13 29 37 181 66
199 154 351 234 403 49 32 96 34 121 6z"
      />
    </g>
  </svg>
);
