import { apportMineraux } from './product/apportMineraux';
import { apportQ10 } from './product/apportQ10';
import { apportVitamines } from './product/apportVitamines';
import { apresShampooing } from './product/apresShampooing';
import { baumeLevres } from './product/baumeLevres';
import { bb } from './product/bb';
import { bienEtreInterieur } from './product/bienEtreInterieur';
import { boosterEnergie } from './product/boosterDenergie';
import { cocoOil } from './product/cocoOil';
import { collagenoni } from './product/collagenoni';
import { cremeDay } from './product/cremeDay';
import { cremeNight } from './product/cremeNight';
import { decontaminant } from './product/decontaminant';
import { dentifrice } from './product/dentifrice';
import { dentitionBlanche } from './product/dentitionBlanche';
import { detox } from './product/detox';
import { eauTonique } from './product/eauTonique';
import { equilibreCorpsEsprit } from './product/equilibreCorpsEsprit';
import { gelDouche } from './product/gelDouche';
import { gelNettoyant } from './product/gelNettoyant';
import { gg } from './product/gg';
import { glossRepulpant } from './product/glossRepulpant';
import { gourdEau } from './product/gourdEau';
import { goutteMinceur } from './product/goutteMinceur';
import { guerisseurMaux } from './product/guerisseurMaux';
import { harmonie } from './product/harmonie';
import { heDesinfectante } from './product/heDesinfectante';
import { heRafraichissant } from './product/heRafraichissant';
import { heRelaxante } from './product/heRelaxante';
import { heSoulageante } from './product/HeSoulageante';
import { huileDemaquillante } from './product/huileDemaquillante';
import { masqueNuit } from './product/masqueNuit';
import { mereDeTous } from './product/mereDeTous';
import { osMuscle } from './product/osMuscle';
import { pb } from './product/pb';
import { pn } from './product/pn';
import { purificatAir } from './product/purificatAir';
import { regenerateurCellulaire } from './product/regenerateurCellulaire';
import { santeMax } from './product/santeMax';
import { santePlus } from './product/santePlus';
import { serumComplet } from './product/serumComplet';
import { serumFlash } from './product/serumFlash';
import { shampooing } from './product/shampooing';
import { volumateurCils } from './product/volumateurCils';

export const productList = [
  apportMineraux,
  apportQ10,
  apportVitamines,
  apresShampooing,
  baumeLevres,
  bb,
  boosterEnergie,
  bienEtreInterieur,
  cocoOil,
  collagenoni,
  cremeDay,
  cremeNight,
  decontaminant,
  dentifrice,
  dentitionBlanche,
  detox,
  eauTonique,
  equilibreCorpsEsprit,
  gelDouche,
  gelNettoyant,
  glossRepulpant,
  gourdEau,
  goutteMinceur,
  guerisseurMaux,
  gg,
  harmonie,
  heDesinfectante,
  heRafraichissant,
  heRelaxante,
  heSoulageante,
  huileDemaquillante,
  masqueNuit,
  mereDeTous,
  osMuscle,
  pb,
  pn,
  purificatAir,
  regenerateurCellulaire,
  santeMax,
  santePlus,
  serumComplet,
  serumFlash,
  shampooing,
  volumateurCils,
];
