export const LogoUP = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 450"
    preserveAspectRatio="xMidYMid meet"
    className="logo-upps"
  >
    <g
      transform="translate(0,450) scale(0.1,-0.1)"
      fill="currentColor"
      stroke="none"
    >
      <g className="svg-group-1">
        <path
          d="M2115 4299 c-191 -220 -280 -419 -292 -654 -19 -369 190 -772 672
-1295 336 -364 471 -618 491 -923 18 -279 -141 -701 -331 -876 -87 -80 -331
-180 -547 -223 -93 -18 -146 -22 -298 -22 -208 0 -280 7 -637 66 l-252 41 82
52 c254 160 764 384 874 385 61 0 42 17 -25 23 -139 11 -308 -23 -549 -114
-203 -76 -504 -214 -712 -327 l-85 -46 47 -41 c189 -166 569 -255 1030 -242
218 6 334 24 487 73 201 66 399 182 555 326 44 40 112 100 150 133 456 390
567 840 330 1342 -85 180 -190 322 -410 553 -457 481 -686 916 -685 1307 1
182 53 356 146 485 19 28 34 52 32 54 -2 2 -35 -33 -73 -77z"
        />
        <path
          d="M2508 3705 c-99 -36 -158 -117 -166 -224 -3 -53 0 -77 15 -112 47
-104 130 -159 242 -159 79 0 134 24 186 82 50 54 65 95 65 171 0 179 -175 303
-342 242z"
        />
        <path
          d="M3735 3394 c-361 -301 -472 -413 -583 -589 -34 -55 -116 -225 -110
-230 1 -2 32 30 68 71 78 90 242 241 365 337 155 121 406 280 420 266 3 -4 10
-56 15 -117 30 -343 -73 -828 -251 -1184 -22 -43 -39 -80 -39 -83 0 -15 92 60
151 122 162 172 289 444 356 762 23 110 26 149 26 311 0 168 -3 194 -27 285
-24 94 -77 219 -107 257 -13 16 -39 -3 -284 -208z"
        />
        <path
          d="M3935 1869 c-83 -12 -234 -49 -241 -60 -3 -5 25 -9 63 -9 151 0 402
-37 615 -91 108 -27 363 -110 375 -122 7 -8 -29 -58 -94 -134 -109 -126 -300
-266 -488 -357 -152 -75 -278 -118 -500 -171 -53 -13 -1 -24 119 -24 262 -2
616 116 888 295 129 85 277 231 340 334 49 82 95 186 86 196 -9 8 -662 130
-768 143 -98 13 -308 12 -395 0z"
        />
      </g>
      <g className="svg-group-2">
        <path
          d="M3005 4335 c47 -62 107 -189 130 -275 10 -38 19 -119 22 -195 4 -107
1 -148 -17 -234 -57 -277 -190 -535 -434 -841 l-75 -94 29 -35 c16 -20 57 -64
92 -99 l64 -62 94 117 c344 431 486 811 426 1143 -37 203 -163 430 -327 585
l-41 40 37 -50z"
        />
        <path
          d="M1096 3567 c-138 -235 -154 -629 -41 -1000 69 -230 178 -437 298
-572 52 -58 155 -140 164 -131 2 2 -8 23 -21 47 -40 71 -121 266 -156 374 -70
219 -107 430 -116 659 -5 127 5 289 20 303 8 8 150 -75 266 -155 159 -109 288
-218 442 -373 157 -158 158 -158 80 -4 -81 162 -208 317 -378 464 -177 153
-501 421 -517 427 -10 4 -24 -9 -41 -39z"
        />
        <path
          d="M2302 2338 c-260 -328 -374 -696 -317 -1021 40 -230 170 -456 372
-647 85 -81 159 -137 168 -128 2 2 -14 26 -37 54 -205 247 -321 566 -304 831
15 230 124 484 303 706 l54 67 -21 28 c-23 29 -160 172 -165 172 -2 0 -26 -28
-53 -62z"
        />
        <path
          d="M770 1866 c-75 -11 -557 -100 -718 -132 -40 -8 -39 -13 23 -139 152
-307 569 -577 1045 -676 118 -24 311 -31 345 -12 18 11 18 12 -10 18 -365 84
-640 216 -877 419 -66 57 -208 218 -208 236 0 10 185 75 306 109 215 59 429
95 659 110 l110 7 -55 17 c-166 51 -433 70 -620 43z"
        />
        <path
          d="M3255 860 c-4 -6 6 -10 22 -10 130 -1 589 -204 941 -417 20 -13 32
-23 27 -23 -6 0 -103 -16 -215 -35 -365 -61 -438 -69 -675 -69 -276 0 -362 14
-615 102 -132 46 -131 27 4 -62 162 -106 361 -186 556 -223 134 -25 608 -25
755 0 164 29 284 65 400 122 106 52 206 121 202 138 -2 5 -65 41 -142 81 -363
188 -729 338 -947 386 -95 22 -302 28 -313 10z"
        />
      </g>
    </g>
  </svg>
);
